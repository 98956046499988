import { Group, Line,Rect, Transformer,Text } from "react-konva";
import React, { useRef, useEffect, useState } from "react";
import ShapeStyle from "./ShapeStyle";
import TextInput from "./TextInput";
import { useDispatch, useSelector } from "react-redux";
import {
  removeInFrames,
} from "../../../Redux/actions/frame";

import {
  editStageTool
} from "../../../Redux/actions/tools";


const lineShape = React.memo(({ shapeProps }) => {

  const shapeRef = useRef();
  const trRef = useRef();
  const {  selectedFrame } = useSelector(
    (store) => store.frameRoot
  );
  const { editToolName,editedToolId,editToolData } = useSelector((store) => store.toolRoot);

  const dispatch = useDispatch();
  const [editing, setEditing] = useState(shapeProps.id === editToolData?.id);
  const [transform, setTransform] = useState(false);

  useEffect(() => {
    if (editing) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [editing]);

  useEffect(() => {
    // Remove transformer when selectedlineShape.id changes
    if (shapeProps.id !== editToolData?.id && editing && !transform) {
      setEditing(false);
    }
  }, [shapeProps.id, editToolData?.id, editing]);

  const onDragEnd = () => {
   
    const node = shapeRef.current;
    const linesdata = node.getClientRect();
    let x1 = linesdata.x;
    let y1 = linesdata.y;
    let x2 = linesdata.x + linesdata.width;
    let y2 = linesdata.y;
    console.log("x1",x1,"y1",y1,"x2",x2,"y2",y2)
    dispatch(
      editStageTool(selectedFrame?.boardId,selectedFrame?._id, { ...editToolData, x1,y1,x2,y2 },editToolName)
    );
    setEditing(false)
    setTransform(false);
  };


  const handleTransformEnd = () => {

    const node = shapeRef.current;
    const linesdata = node.getClientRect();
    let x1 = linesdata.x;
    let y1 = linesdata.y;
    let x2 = linesdata.x + linesdata.width;
    let y2 = linesdata.y;
    console.log("x1",x1,"y1",y1,"x2",x2,"y2",y2)
    dispatch(
        editStageTool(selectedFrame?.boardId,selectedFrame?._id, { ...editToolData, x1,y1,x2,y2,width:linesdata.width },editToolName)
    );
    setEditing(false)
setTransform(false);


}
  const handleDoubleClick = () => {
    console.log("on double click",shapeProps)
    dispatch({
      type: "EDIT_SELECTED_TOOL",
      payload: { isEditToolSelected: true, editToolName :"lineShape", editToolData: shapeProps,editedToolId: shapeProps.id },
    });
    setEditing(true);
    setTransform(true);
  };
  const handleBlur = (deleting) => {
    if (editToolData && Object.keys(editToolData).length > 0 && !deleting && !transform) {
    setEditing(false);
      console.log("Call",editToolData)
      dispatch(editStageTool(selectedFrame?.boardId,selectedFrame?._id, editToolData,editToolName));
  }
  };

  const handleChange = (value) => {
    setTransform(false);
    dispatch({
      type: "UPDATE_EDIT_SELECTED_TOOL",
      payload: { name:"text",value},
    });
  };
  return (
    <React.Fragment>
        <Rect
        x={shapeProps?.x1}
        y={shapeProps?.y1}
        id="rectangle"
        draggable
        onClick={handleDoubleClick}
      />
      <Line
            onClick={() => handleDoubleClick()}       
            points={[shapeProps.x1, shapeProps.y1, shapeProps.x2, shapeProps.y2]}
            stroke="black"
            strokeWidth={2}
            draggable
            onDragEnd={()=>onDragEnd()}
            ref={shapeRef}
            onTransformEnd={handleTransformEnd}
          />

        <Text
        fontSize={15}
        fill="white"
        x={shapeProps?.x1}
        y={shapeProps?.y1}
        width={shapeProps?.width}
        height={shapeProps?.height}
        align="center"
        verticalAlign="middle"
        id="lineShapeId"
        onClick={handleDoubleClick}
        ref={trRef}
      />
      <TextInput
        editing={editing}
        x={shapeProps?.x1}
        y={shapeProps?.y1}
        value={shapeProps?.text}
        width={shapeProps?.width}
        height={shapeProps?.height}
        onBlur={() => handleBlur(false)}
        onChange={(e) => handleChange(e)}
        onSelect={() => handleDoubleClick()}
      />


      {editing && (
         <Transformer
         ref={trRef}
         enabledAnchors={['middle-left', 'middle-right']}
         boundBoxFunc={(oldBox, newBox) => {
           // limit resize
           if (newBox.width < 5) {
             return oldBox;
           }
           return newBox;
         }}
        //  onTransformEnd={handleTransformEnd}
       />
      )}
      {editing && (
        <ShapeStyle
          x={shapeProps.x1}
          y={shapeProps.y1 - 60}
          onDelete={() =>
            dispatch(
              removeInFrames(selectedFrame?.boardId,selectedFrame?._id, editToolData, "lineShape")
            )
          }
          onClose={() => setEditing(false)}
          onColorChange={(color) =>{
            dispatch(
              editStageTool(selectedFrame?.boardId,selectedFrame?._id, {
                ...editToolData,
                fill: color,
              },editToolName)
            )
          }
        }
        />
      )}
    </React.Fragment>
  );
});
export default lineShape;
