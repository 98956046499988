const initialState = {
  toolData: {},
  isToolSelected: false,
  selectToolName: "",
  selectedIcon: "",
  editedToolId: "",
  editToolName: "",
  editToolData: {},
  isEditToolSelected: false,
};

const ToolReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TOOL_DATA":
      return {
        toolData: action.payload.toolData,
        isToolSelected: action.payload.isToolSelected,
        selectToolName: action.payload.selectToolName,
      };

    case "SET_SELECTED_ICON":
      return {
        selectedIcon: action.payload,
      };
    case "SET_SELECTED_TOOLNAME":
      return {
        selectToolName: action.payload,
      };
    case "EDIT_SELECTED_TOOL":
      return {
        editToolName: action.payload.editToolName,
        editToolData: action.payload.editToolData,
        isEditToolSelected: action.payload.isEditToolSelected,
        editedToolId: action.payload.editedToolId,
      };
    case "UPDATE_EDIT_SELECTED_TOOL":
      return {
        ...state,
        editToolData: {
          ...state.editToolData,
          [action.payload.name]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default ToolReducer;
