import React, { useRef, useEffect, useState } from "react";
import { Image, Transformer, Group } from "react-konva";
import IconsStyle from "./IconsStyle";

const Icons = ({
  image,
  imageProps,
  index,
  handleSelect,
  isSelected,
  onChange,
}) => {
  const imageRef = useRef();
  const transformerRef = useRef();
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    if (isSelected) {
      transformerRef.current.nodes([imageRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);
  const [lock, setLock] = useState(false);
  const handleLock = (e) => {
    setLock(!lock);
  };

  const handleDragEnd = (e) => {
    const node = imageRef.current;
    onChange({
      ...imageProps,
      x: node.x(),
      y: node.y(),
    });
  };

  const handleTransformEnd = (e) => {
    const node = imageRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // Reset scale back to 1
    node.scaleX(1);
    node.scaleY(1);

    onChange({
      ...imageProps,
      x: node.x(),
      y: node.y(),
      // Set minimal value
      width: Math.max(50, node.width() * scaleX),
      height: Math.max(5, node.height() * scaleY),
    });
  };

  return (
    <>
      <Group>
        <Image
          key={index}
          image={image}
          ref={imageRef}
          x={imageProps.x}
          y={imageProps.y}
          width={imageProps.width}
          height={imageProps.height}
          draggable={lock ? false : true}
          onClick={handleSelect}
          onDragEnd={handleDragEnd}
          onTransformEnd={handleTransformEnd}
        />
      </Group>
      {isSelected && (
        <Transformer
          ref={transformerRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 50 || newBox.height < 50) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
      {isSelected && (
        <IconsStyle
          x={imageProps.x}
          y={imageProps.y - 60}
          onLock={handleLock}
        />
      )}
    </>
  );
};

export default Icons;
