import { combineReducers } from "redux";
import boardReducer from "./boardReducer";
import frameReducer from "./frameReducer";
import toolReducer from "./toolReducer";
import { persistReducer } from "redux-persist";
import storageSession from "reduxjs-toolkit-persist/lib/storage/session";

const boardPersistConfig = {
  key: "board",
  storage: storageSession,
};
const framePersistConfig = {
  key: "frame",
  storage: storageSession,
};

const toolPersistConfig = {
  key: "tools",
  storage: storageSession,
};

export default combineReducers({
  boardRoot: persistReducer(boardPersistConfig, boardReducer),
  frameRoot: persistReducer(framePersistConfig, frameReducer),
  toolRoot: persistReducer(toolPersistConfig, toolReducer),
});
