import React from "react";
import Login from "./components/Login/Login";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import Cookies from "js-cookie";
import DrawingArea from "./components/Draw/DrawingArea";
import Loader from "./Loader";
import {COOKIES_TOKEN} from "./components/services/url";
import jwt_decode from "jwt-decode";

// // Token
if (!Cookies.get('account_token')) {
    localStorage.clear();
    window.location.href = 'https://accounts.appsdeployer.com';
} else {
  localStorage.setItem('token', Cookies.get('account_token'));
}

if (COOKIES_TOKEN) {
  const decoded = jwt_decode(COOKIES_TOKEN);
  localStorage.setItem("user_id", decoded?._doc?.userId?._id);
}
const App = () => {
  let token = COOKIES_TOKEN;
  return (
    <Router>
      {token ? (
        <Routes>
          <Route
            exact
            path="/drawing/:boardId"
            element={<DrawingArea />}
          />

          <Route exaxt path="/" element={<Dashboard />} />
        </Routes>
      ) : (
        <Routes>
          {/* <Route exaxt path="/" element={<Login />} /> */}
           <Route path="/" element={<Loader/>} />
        </Routes>
      )}
    </Router>
  );
};

export default App;
