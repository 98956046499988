const initialState = {
  boardData: [],
  selectedBoard: {},
};

const BoardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_BOARD":
      return {
        ...state,
        boardData: [...state.boardData,action.payload],
      };
    case "SET_SELECTED_BOARD":
      return {
        ...state,
        selectedBoard: action.payload,
      };  
    case "CLEAR_BOARD":
      return {
        ...state,
        boardData: [],
      };
    case "FETCH_ALL_BOARD":
      return {
        ...state,
        boardData: action.payload,
      };
    case "DELETE_BOARD":
      return {
        ...state,
        boardCount: state.boardCount - 1,
        boardData: state.boardData.filter(
          (board) => board._id !== action.payload
        ),
      };

    default:
      return state;
  }
};

export default BoardReducer;
