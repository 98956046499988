import React, { useEffect, useState } from "react";
import { Stage, Layer, Line, Arrow ,Text,Circle} from "react-konva";
import ImageUpload from "../imageUpload";
import { Colors } from "../../../utils/Colors";
import TextNote  from "../Text/TextNotes";
import Rectangle  from "../Shape/Rectangle";
import Square  from "../Shape/Square";
import CircleShape from "../Shape/CircleShape";
import Triangle  from "../Shape/Triangle";
import Hexagon  from "../Shape/Hexagon";
import Pentagon from "../Shape/Pentagon";
import Sticky from "../Sticky";
import Icons from "../../Icons/Icons";
import { useSelector } from "react-redux";
import LineShape from "../Shape/LineShape";
const StageArea = React.memo(
  ({
  layerRef,
  handleWhiteboardClick,
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  handleSelect,
  handleSelectIcon,
  handleDragEnd,
  handleResize,
  selectedSqareId,
  images,
  icons,
  isDraggable,
  bg,
  stageRef,
  setIcons,
  arrows,
  lines,
}) => {

  const { selectedFrame } = useSelector((store) => store.frameRoot);
  return (
    <Stage
      draggable={isDraggable}
      dragBoundFunc={(pos) => {
        let newX = pos.x;
        let newY = pos.y;

        if (newX > 0) {
          newX = 0;
        } else if (newX < -selectedFrame?.stageWidth) {
          newX = -selectedFrame?.stageWidth;
        }

        if (newY > 0) {
          newY = 0;
        } else if (newY < -selectedFrame?.stageHeight) {
          newY = -selectedFrame?.stageHeight;
        }

        return {
          x: newX,
          y: newY,
        };
      }}
      width={selectedFrame?.stageWidth}
      height={selectedFrame?.stageHeight}
      scaleX={selectedFrame?.scale?.x}
      scaleY={selectedFrame?.scale?.y}
      onClick={handleWhiteboardClick}
      onMouseDown={(e) => {
        handleMouseDown(e);
      }}
      onMousemove={(e) => handleMouseMove(e)}
      onMouseup={(e) => handleMouseUp(e)}
      className="canvas-stage"
      ref={stageRef}
      style={{
        background: bg ? Colors.LIGHTGRAY : Colors.PUREWHITE,
        borderRadius: "4px",
        position: "absolute",
        zIndex: "-1",
        top: "0%",
      }}
      key={selectedFrame?._id}
    >
      <Layer ref={layerRef}>
        {arrows.map((arrow, index) => (
          <Arrow
            key={arrow.id}
            points={[arrow.startX, arrow.startY, arrow.endX, arrow.endY]}
            stroke={arrow.color}
            strokeWidth={arrow.strokeWidth}
            draggable
          />
        ))}
        {selectedFrame?.lines?.map(
          (line, i) =>
            line &&
            line.data.length > 0 &&
            line?.data?.map((item, index) => (
              <Line
                key={index}
                points={item.points}
                stroke={item.stroke}
                strokeWidth={item.strokeWidth}
              />
            ))
        )}
        {lines?.map((line, index) => (
          <Line
            key={index}
            points={line.points}
            stroke={line.stroke}
            strokeWidth={line.strokeWidth}
          />
        ))}
        {selectedFrame?.rectangle?.map((rect, i) =>
          rect && <Rectangle key={i} shapeProps={rect} /> 
        )}
        {selectedFrame?.triangle?.map((triangle, i) =>
          triangle && (
            <Triangle {...triangle} key={triangle.id} shapeProps={triangle} />
          ) 
        )}
        {selectedFrame?.square?.map((sq, i) =>
          sq && (
            <Square
              {...sq}
              key={i}
              shapeProps={sq}
              isSelected={sq.id === selectedSqareId}
            />
          ) 
        )}
        {selectedFrame?.circle?.map((cir, i) =>
          cir && <CircleShape {...cir} key={i} shapeProps={cir} /> 
        )}
        {selectedFrame?.pentagon?.map((pent, i) =>
          pent && <Pentagon key={i} shapeProps={pent} /> 
        )}
        {selectedFrame?.hexagon?.map((hex, i) =>
          hex && <Hexagon key={i} shapeProps={hex} /> 
        )}

        {selectedFrame?.text?.map((tnote) =>
          tnote && (
            <TextNote
              {...tnote}
              key={tnote.id}
              textNote={tnote}
            />
          )
        )}

        {selectedFrame?.stickyNotes?.map((note, index) => {
          return (
            note && (
              <Sticky
                key={index}
                {...note}
                stickyProps={note}
              />
            )
          );
        })}

        {selectedFrame?.lineShape?.map((line, index) => {
                  return (
                    line && (
                      <LineShape 
                      key={index}
                        {...line}
                        stroke="black"
                        strokeWidth={2}
                        shapeProps={line}
                      />
                    )
                  );
        })}

        {images.length > 0 &&
          images.map((image) => (
            <ImageUpload
              key={image.id}
              image={image}
              onSelect={() => handleSelect(image.id)}
              onChange={(x, y) => handleDragEnd(image.id, x, y)}
              onResize={(width, height) =>
                handleResize(image.id, width, height)
              }
            />
          ))}

        {/* icons elements*/}
        {images.length > 0 &&
          icons.map(({ id, image, ...imageProps }) => (
            <Icons
              key={id}
              image={image}
              imageProps={imageProps}
              index={id - 1}
              handleSelect={() => handleSelectIcon(id)}
              onChange={(newAttrs) => {
                const updatedImages = icons.map((img) => {
                  if (img.id === id) {
                    return {
                      ...img,
                      ...newAttrs,
                    };
                  }
                  return img;
                });
                setIcons(updatedImages);
              }}
            />
          ))}
        {/*  to see live users movement */ }
        {selectedFrame?.usersAxis?.map((userPoint, index) => (
          localStorage.getItem("user_id") !== userPoint?.userId &&
          <React.Fragment key={index}>
          <Arrow
          x={userPoint?.x}
          y={userPoint?.y}
          pointerLength={5}
          pointerWidth={5}
          fill={userPoint?.userColor}
          stroke={userPoint?.userColor}
          />

        <Text
          x={userPoint?.x - 20} // Adjust the text position according to the arrow
          y={userPoint?.y + 20} // Adjust the text position according to the arrow
          text={userPoint?.username}
          fill={userPoint?.userColor}
        />
        </React.Fragment>
))}
         
      </Layer>
    </Stage>
  );
});
export default StageArea;
