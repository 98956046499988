import React, { useState, useEffect, useRef } from "react";
import { Html } from "react-konva-utils";


const EditableTextInput = React.memo (({
  x,
  y,
  width,
  height,
  onChange,
  onClick,
  onBlur,
  fontSize,
  italic,
  textAlign,
  fontWeight,
  fontFamily,
  textDecoration,
  textColor,
  text,
  frameId,
  id,
}) => {
  const [inputValue, setInputValue] = useState(text);
  
  const [myWidth, setMyWidth] = useState(null);
  const [myHeight, setMyHeight] = useState(null);
  const [currentFont, setCurrentFont] = useState(fontSize);
  const textareaRef = useRef(null);
  const [scrollHeight, setScrollHeight]=useState(null);
  const [clientHeight, setClientHeight]=useState(null);
  const uniqueId = `editableTextInput_${frameId}`;

  const getStyle = () => {
    const isFirefox =
      navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    const baseStyle = {
      width: `${width}px`,
      height: `${height}px`,
      border: "none",
      padding: "10px 20px",
      margin: "5px",
      background: "none",
      outline: "none",
      textDecoration: textDecoration ? "underline" : "none",
      color: textColor,
      fontSize: `${fontSize}px`,
      fontFamily: fontFamily,
      textAlign: textAlign,
      fontStyle: italic ? "italic" : "normal",
      fontWeight: fontWeight ? "bold" : "normal",
      overflow: "hidden", 
      resize: "none", 
    };
    if (isFirefox) {
      return baseStyle;
    }
    return {
      ...baseStyle,
      marginTop: "-4px",
    };
  };
  
  const handleChange = (event) => {
    setInputValue(event.target.value);
    onChange(event.target.value, currentFont, id);
  };

  useEffect(() => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      
      setScrollHeight(textarea.scrollHeight);
      setClientHeight(textarea.clientHeight);

      if (textarea.value.trim() === "") {
        setCurrentFont(50); // Set font size to default if textarea is empty
      } 

      if (textarea.scrollHeight > textarea.clientHeight) {
        setCurrentFont((prevFont) => Math.max(prevFont - 2, 10)); 
      }
    }
    setMyWidth(width);
    setMyHeight(height);
  }, [width, height,fontSize, scrollHeight, clientHeight, x, y, frameId]);

  useEffect(() => {
    setInputValue(text);
  }, [text,fontSize]);

  return (
    <Html groupProps={{ x, y }} divProps={{ backgroundColor: "red" }} key={uniqueId}>
      <textarea
        onClick={onClick}
        ref={textareaRef}
        value={inputValue}
        onChange={handleChange}
        onBlur={onBlur}
        style={{
          ...getStyle(fontSize),
          fontSize: `${fontSize}px`,
          width: `${myWidth - 30}px`,
          height: `${myHeight - 40}px`,
        }}
      />
    </Html>
  );
})
export default EditableTextInput;
