import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { io } from "socket.io-client";

export const COOKIES_TOKEN = Cookies.get("account_token");

//Prod url
export const HOST_URL ='https://api.appsdeployer.com/';

// export const HOST_URL =`http://localhost:8082/`;

export const API_URL = `${HOST_URL}api/`;

export const SOCKET_URL = `${HOST_URL}`;

export const socket = io(SOCKET_URL, {
    transports: ["websocket"], // Explicitly specify WebSocket transport
});

export const socketJoin = (boardId) => {
    const decoded = jwt_decode(COOKIES_TOKEN);
    socket.emit(
      "whiteboard_join",
      {
        room: boardId,
        userId: decoded._doc.userId._id,
        username: decoded._doc.userId.username,
        userColor: decoded._doc.userId.userColor,
      },
      (acknowledgment) => {
        if (acknowledgment && acknowledgment.success) {
          // The server acknowledged the successful join
          // console.log("Join board rook:", acknowledgment);
        } else {
          // Handle the case where the join was not successful
          console.error("Join failed:", acknowledgment && acknowledgment.error);
        }
      },
    );
  };

export const userAxisUpdate = (x,y,boardId) =>{
  socket.emit("user_update_send",
  {  
    userId:jwt_decode(COOKIES_TOKEN)._doc.userId._id,
    username:jwt_decode(COOKIES_TOKEN)._doc.userId.username,
    x,
    y,
    boardId
  })
}  
  