import React, { useState, useRef } from "react";
import { Html } from "react-konva-utils";

import { Colors } from "../../utils/Colors";

const containerStyle = {
  display: "flex",
  justifyContent: "space-between",
  border: "2px solid black",
  borderRadius: "10px",
  gap: "10px",
  backgroundColor: Colors.WHITE,
  padding: "10px",
};

const IconsStyle=({ x, y, onLock })=> {
  const [lock, setUnlock] = useState(true);
  const groupRef = useRef(null);
  const handleLock = () => {
    setUnlock(!lock);
    onLock();
  };

  return (
    <>
      <Html groupRef={groupRef} groupProps={{ x, y }} divProps={containerStyle}>
        <div style={containerStyle}>
          <span>
            <i class="fa-sharp fa-regular fa-rectangle" />
          </span>
          {lock ? (
            <span>
              <i className="fa fa-lock-open" onClick={handleLock}/>
            </span>
          ) : (
            <span>
              <i className="fa fa-lock" onClick={handleLock}/>
            </span>
          )}
        </div>
      </Html>
    </>
  );
}

export default IconsStyle;
