import React, { useState, useEffect, useRef } from "react";
import {
  addInFrames,
  getFrameById,
} from "../../Redux/actions/frame";
import DrawerHeader from "./drawerHeader";
import AnimatedCursorPointer from "./AnimatedCursorPointer";
import { useImage } from "./hooks/useImage";
import { useArrow } from "./hooks/useArrow";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toolIds } from "../../utils/ToolId";
import styles from "./drawingArea.module.css";
import SidebarTool from "./tools/sidebarTool";
import BottomTool from "./tools/BottomTool";
import StageArea from "./tools/stageArea";
import { socketJoin, socket, userAxisUpdate } from "../services/url";

const DrawingArea = () => {
  const stageRef = useRef(null);
  const layerRef = useRef(null);

  const dispatch = useDispatch();
  const { selectedFrame, penstroke } = useSelector(
    (store) => store.frameRoot
  );
  const { toolData, selectToolName } = useSelector((store) => store.toolRoot);
  const [lines, setLines] = useState([]);

  useEffect(() => {
    getFrame();
  },[dispatch]);

  const { boardId } = useParams();

  useEffect(() => {
    socketJoin(boardId);
  }, []);

  useEffect(() => {

    //new add receive in frame
    socket.on("add_new_tool_receive", ({ data, type }) => {
      dispatch({
        type: "UPDATE_FRAME",
        payload: { type, data },
      });
    });

    //edit changes receive in frame
    socket.on("edit_frame_receive", ({ data, type }) => {
      dispatch({
        type: "UPDATE_SELECTED_EDIT_TOOL",
        payload: {
          data,
          type,
          id: data.id,
        },
      });
      dispatch({
          type: "EDIT_SELECTED_TOOL",
          payload: { isEditToolSelected: true, editToolName :type, editToolData: data,editedToolId: data.id },
      });
    });

    // delete from frame
    socket.on("remove_from_frame_receive", ({ data, type }) => {
      dispatch({
        type: "REMOVE_FROM_FRAME",
        payload: { undoData: data, type },
      });
    });

    //redo update in frame
    socket.on("redo_from_frame_receive", ({ data, type }) => {
      dispatch({
        type: "REDO_FRAME",
        payload: { data,type },
      });
    });

    //clear frame receive
    socket.on("clear_frame_receive", (frameId) => {
      dispatch({
        type: "CLEAR_FRAME",
        payload: frameId,
      });
    })

    //zoom frame receive
    socket.on("zoom_frame_receive", ({data,frameId}) => {

      dispatch({
        type: "UPDATE_SELECTED_FRAME",
        payload: {data,frameId},
      });
    })

    socket.on("user_update_receive",(users)=>{
      dispatch({
        type: "UPDATE_USERS_FRAME",
        payload: {usersAxis:users},
      });
    })

  }, []);

  const getFrame = async () => {
    dispatch(getFrameById(boardId));
  };

  const { images, setImages, handleDragEnd, handleImageUpload, handleResize } =
    useImage();
  const { arrows, setArrows } = useArrow();

  // iamges
  const [selectedId, setSelectedId] = useState(null);
  const handleSelect = (id) => {
    setSelectedId(id);
  };

  // popover elements
  // fucntion for export the canvas part only
  const handleExport = () => {
    alert("Ready to download.");
    const stage = stageRef.current;
    const canvas = stage.toCanvas();
    const dataURL = canvas.toDataURL("image/jpeg");

    const link = document.createElement("a");
    link.href = dataURL;
    link.download = "stage.jpg";

    link.click();
  };
 

  const [drawing, setDrawing] = useState(false);

  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });

  const [isMoving, setIsMoving] = useState(false);

  const handleMouseDown = () => {
    setDrawing(true);
    setIsMoving(true);
    const stage = layerRef.current.getStage();
    const { x, y } = stage.getPointerPosition();
    const scaledX = x / selectedFrame.scale.x;
    const scaledY = y / selectedFrame.scale.y;
    setStartPoint({ x: scaledX, y: scaledY });
  };

  // Functions calling when the mouse move on the board for start draawing
  const handleMouseMove = (e) => {
    if (
      drawing &&
      (selectToolName === "line" ||
        selectToolName === "line2" ||
        selectToolName === "line3" ||
        selectToolName === "brush" ||
        selectToolName === "eraser")
    ) {
      const stage = layerRef.current.getStage();
      const scale = stage.scale(); // Get the current scale of the stage
      const { x, y } = stage.getPointerPosition();

      // Adjust the coordinates based on the scale
      const scaledX = x / scale.x;
      const scaledY = y / scale.y;

      const newLine = {
        id: lines.length,
        points: [startPoint.x, startPoint.y, scaledX, scaledY], // Adjusted line
        stroke: penstroke?.stroke,
        strokeWidth: penstroke?.strokeWidth,
      };

      setLines([...lines, newLine]);
      setStartPoint({ x: scaledX, y: scaledY });
    }
    else if(isMoving){
      const stage = layerRef.current.getStage();
      const { x, y } = stage.getPointerPosition();
      const scaledX = x / selectedFrame.scale.x;
      const scaledY = y / selectedFrame.scale.y;
      userAxisUpdate(scaledX, scaledY,selectedFrame?.boardId);
    } 
  };
  // Functions calling when the mouse not click and start stop drawing
  const handleMouseUp = (e) => {
    setDrawing(false);
    if (lines?.length > 0) {
      if (selectToolName === "line" || "line2" || "line3" || "brush") {
       
          let data = {
            id: "line" + selectedFrame?.lines?.length + 1,
            data: lines,
          };
          dispatch(
            addInFrames(
              selectedFrame?.boardId,
              selectedFrame?._id,
              data,
              "lines"
            )
          );
        
      }
      setLines([]);
    }
  };


  // color for different tools with diffeent color options

  const handleWhiteboardClick = (e) => {
    if (selectToolName) {
      const stage = stageRef.current;
      const point = stage.getPointerPosition();
      const gridX = point.x / selectedFrame?.scale.x;
      const gridY = point.y / selectedFrame?.scale.y;

      if (
        selectToolName === toolIds.SQUARE ||
        selectToolName === toolIds.RECTANGLE ||
        selectToolName === toolIds.STICKY ||
        selectToolName === toolIds.TEXT
      ) {
        dispatch({
          type: "ADD_INTO_UNDO_STACK",
          payload: selectToolName,
        });
        AddNewItems(gridX, gridY, selectToolName);
      } else if (selectToolName === toolIds.TRIANGLE) {
        dispatch({
          type: "ADD_INTO_UNDO_STACK",
          payload: selectToolName,
        });

        // console.log("gridX",gridX,gridY)
        const triangleWidth = 400;
        // Center coordinates of the canvas
        const triangleCenterX = gridX;
        const triangleCenterY = gridY;

        // Calculate the side length of the equilateral triangle
        const sideLength = (triangleWidth * Math.sqrt(3)) / 3;
        // Calculate dimensions for the inscribed rectangle
        const rectangleWidth = sideLength / Math.sqrt(6);
        const rectangleX = triangleCenterX - rectangleWidth / 2;
        const rectangleY = triangleCenterY - rectangleWidth / 2;

        // Calculate the radius of the circle circumscribing the triangle
        const radius = (triangleWidth * Math.sqrt(3)) / 6;
        dispatch(
          addInFrames(
            selectedFrame?.boardId,
            selectedFrame?._id,
            {
              ...toolData,
              width: triangleWidth,
              triangleCenterX,
              triangleCenterY,
              sideLength,
              rectangleWidth,
              rectangleHeight: rectangleWidth,
              rectangleX,
              rectangleY,
              radius,
            },
            selectToolName
          )
        );
        clearTool();
      } else if (selectToolName === toolIds.PENTAGON) {
        dispatch({
          type: "ADD_INTO_UNDO_STACK",
          payload: selectToolName,
        });

        const pentagonWidth = 200; // Initial width
        const pentagonCenterX = gridX; // Center X coordinate of the canvas
        const pentagonCenterY = gridY; // Center Y coordinate of the canvas

        // Calculate side length of the pentagon
        const sideLength = pentagonWidth / (1 + Math.sqrt(5));

        // Calculate dimensions for the inscribed square
        const squareWidth = sideLength * Math.sqrt(2);
        const squareX = pentagonCenterX - squareWidth / 2;
        const squareY = pentagonCenterY - squareWidth / 2;
        const radius = (sideLength * (1 + Math.sqrt(5))) / 2;

        dispatch(
          addInFrames(
            selectedFrame?.boardId,
            selectedFrame?._id,
            {
              ...toolData,
              width: pentagonWidth,
              sideLength,
              pentagonCenterX,
              pentagonCenterY,
              squareWidth,
              squareX,
              squareY,
              radius,
            },
            "pentagon"
          )
        );
        clearTool();
      } else if (selectToolName === toolIds.CIRCLE) {
        dispatch({
          type: "ADD_INTO_UNDO_STACK",
          payload: selectToolName,
        });

        const circleWidth = 200; // Initial width
        const circleCenterX = gridX; // Center X coordinate of the canvas
        const circleCenterY = gridY; // Center Y coordinate of the canvas
        const circleRadius = circleWidth / 2;
        // Calculate side length of the circle
        const sideLength = Math.sqrt(2) * circleRadius;

        // Calculate dimensions for the inscribed square
        const squareWidth = sideLength;
        const squareX = circleCenterX - squareWidth / 2;
        const squareY = circleCenterY - squareWidth / 2;

        dispatch(
          addInFrames(
            selectedFrame?.boardId,
            selectedFrame?._id,
            {
              ...toolData,
              width: circleWidth,
              sideLength,
              circleCenterX,
              circleCenterY,
              squareWidth,
              squareX,
              squareY,
            },
            "circle"
          )
        );
        clearTool();
      } else if (selectToolName === toolIds.HEXAGON) {
        const hexagonWidth = 200; // Initial width
        const hexagonCenterX = gridX;
        const hexagonCenterY = gridY;
        const sideLength = hexagonWidth / Math.sqrt(3);
        const rectangleWidth = sideLength / Math.sqrt(2);
        const rectangleHeight = sideLength / Math.sqrt(3);
        const rectangleX = hexagonCenterX - rectangleWidth / 2;
        const rectangleY = hexagonCenterY - rectangleHeight / 2;
        const radius = sideLength / Math.sqrt(3);

        dispatch(
          addInFrames(
            selectedFrame?.boardId,
            selectedFrame?._id,
            {
              ...toolData,
              width: hexagonWidth,
              sideLength,
              radius,
              hexagonCenterX,
              hexagonCenterY,
              rectangleX,
              rectangleY,
              rectangleWidth,
              rectangleHeight,
            },
            "hexagon"
          )
        );
        clearTool();
      } else if(selectToolName === toolIds.LINESHAPE){
       

          dispatch(
          addInFrames(
            selectedFrame?.boardId,
            selectedFrame?._id,
            {
              ...toolData,
              x1: gridX,
              y1:gridY,
              x2: gridX+100,
              y2:gridY,
              width:100,
              height:10,
            },
            selectToolName
          )
        );
        clearTool();

      }

    }
    document.body.style.cursor = "default";
  };

  const clearTool = () => {
    dispatch({
      type: "SET_TOOL_DATA",
      payload: {
        toolData: {},
        isToolSelected: false,
        selectToolName: "",
      },
    });
  };
  const AddNewItems = (gridX, gridY) => {
    dispatch(
      addInFrames(
        selectedFrame?.boardId,
        selectedFrame?._id,
        {
          ...toolData,
          x: gridX,
          y: gridY,
        },
        selectToolName
      )
    );
    clearTool();
  };

  // jsx for the normal text on the baord
  const [textNotes, setTextNotes] = useState([]);

  const [bg, setbg] = useState(false);

  const [isLaser, setIsLaser] = useState(false);

  // jsx for the icons
  const [icons, setIcons] = useState([]);
  const handleSelectIcon = (id) => {
    setSelectedId((prevSelectedId) => (prevSelectedId === id ? null : id));
  };

  // windows width and height
  const [isFullScreen, setIsFullScreen] = useState(false);
  const enterFullScreen = () => {
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
    setIsFullScreen(true);
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    setIsFullScreen(false);
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(
        document.fullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitFullscreenElement ||
          document.msFullscreenElement
      );
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    document.addEventListener("msfullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullScreenChange
      );
    };
  }, []);

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  const toggleFullScreen = () => {
    if (!isFullScreen) {
      enterFullScreen();
    } else {
      exitFullScreen();
    }
  };

  const [isDraggable, setIsDraggable] = useState(false);
  const [isMoveMode, setIsMoveMode] = useState(false);

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const ZoomOut = () => {
    let currentGridsize = selectedFrame?.gridSize - 3;
    let data = {
      gridSize: currentGridsize,
      stageWidth:
        Math.ceil(selectedFrame.stageWidth / currentGridsize) * currentGridsize,
      stageHeight:
        Math.ceil(selectedFrame.stageHeight / currentGridsize) *
        currentGridsize,
      zoomPercentage: Math.floor(selectedFrame?.zoomPercentage - 8),
      scale: {
        x: selectedFrame?.scale.x / 1.1,
        y: selectedFrame?.scale.y / 1.1,
      },
    };
    socket.emit("zoom_frame_send",{boardId:selectedFrame?.boardId,frameId:selectedFrame?._id,data:data,type:"zoomupdate"})
  };

  const ZoomIn = () => {
    let currentGridsize = selectedFrame?.gridSize + 3;
    let data = {
      gridSize: currentGridsize,
      stageWidth:
        Math.ceil(selectedFrame.stageWidth / currentGridsize) * currentGridsize,
      stageHeight:
        Math.ceil(selectedFrame.stageHeight / currentGridsize) *
        currentGridsize,
      scale: {
        x: selectedFrame?.scale?.x * 1.1,
        y: selectedFrame?.scale?.y * 1.1,
      },
      zoomPercentage: Math.floor(selectedFrame?.zoomPercentage + 8),
    };
    socket.emit("zoom_frame_send",{boardId:selectedFrame?.boardId,frameId:selectedFrame?._id,data:data,type:"zoomupdate"})
  };

  return (
    <div className={styles.container}>
      <DrawerHeader
        bgchange={() => setbg(!bg)}
        handleZoomIn={() => {
          ZoomIn();
        }}
        handleZoomOut={() => {
          ZoomOut();
        }}
        handleExport={() => handleExport()}
        board_Id={boardId}
      />
      {isLaser && <AnimatedCursorPointer />}
      <div>
        {/* options for draw in the board from icons select */}
        <SidebarTool
          setIsLaser={setIsLaser}
          isLaser={isLaser}
          handleImageUpload={handleImageUpload}
        />
        <BottomTool
          handleFullScreen={handleFullScreen}
          isFullScreen={isFullScreen}
          toggleFullScreen={toggleFullScreen}
          setIsDraggable={setIsDraggable}
          isDraggable={isDraggable}
          setIsMoveMode={setIsMoveMode}
          isMoveMode={isMoveMode}
        />

        {/* code for drawing boards */}

        <StageArea
          layerRef={layerRef}
          lines={lines}
          handleWhiteboardClick={handleWhiteboardClick}
          handleMouseDown={handleMouseDown}
          handleMouseMove={handleMouseMove}
          handleMouseUp={handleMouseUp}
          handleSelect={handleSelect}
          handleSelectIcon={handleSelectIcon}
          handleDragEnd={handleDragEnd}
          handleResize={handleResize}
          images={images}
          icons={icons}
          textNotes={textNotes}
          setTextNotes={setTextNotes}
          isDraggable={isDraggable}
          stageRef={stageRef}
          setIcons={setIcons}
          arrows={arrows}
        />
      </div>
    </div>
  );
};

export default DrawingArea;
