import React, { useRef, useState, useEffect } from "react";
import { Rect, Group, Transformer } from "react-konva";
import { EditableText } from "./EditableText";
import { Colors } from "../../utils/Colors";
import StickyPopup from "./StickyPopup";
import { useDispatch, useSelector } from "react-redux";
import { editStageTool } from "../../Redux/actions/tools";

const Sticky = ({
  key,
  stickyProps
}) => {
  const shapeRef = useRef();
  const { editToolName, editToolData } = useSelector((store) => store.toolRoot);
  const [editing, setEditing] = useState(stickyProps?.id === editToolData?.id);
  const [transform, setTransform] = useState(false);

  const transformStickyRef = useRef(null);
  const inputRef = useRef(null);
  const [textWriting, setTextWriting] = useState(true);
  const {  selectedFrame } = useSelector(
    (store) => store.frameRoot
  );
  const dispatch = useDispatch();

  const [stickyStyle, setStickyStyle] = useState({
    fontWeight: "normal",
    border: false,
    fontStyle: "normal",
  });



  useEffect(() => {
    if (editing) {
      transformStickyRef.current.nodes([shapeRef.current]);
      transformStickyRef.current.getLayer().batchDraw();
    }
  }, [editing]);

  useEffect(() => {
    // Remove transformer when selectedSquare.id changes
    if (stickyProps.id !== editToolData?.id && editing) {
      setEditing(false);
    }
  }, [stickyProps.id, editToolData?.id, editing]);


  function handleClose() {
    setEditing(!editing);
  }

  const onDragEnd = async (x1, y1) => {
  try {
    let note = { ...editToolData, x: x1, y: y1 };
    dispatch(
      editStageTool(
        selectedFrame?.boardId,
        selectedFrame?._id,
        {
          ...editToolData,
          ...note,
        },
        editToolName
      )
    );
  } catch (error) {
    // Handle errors (e.g., log the error, show an error message)
    console.error('Error updating sticky note:', error);
  }
};

const handleDoubleClick = () => {
  console.log("handleDoubleClick")
  dispatch({
    type: "EDIT_SELECTED_TOOL",
    payload: {
      isEditToolSelected: true,
      editToolName: "stickyNotes",
      editToolData: stickyProps,
      editedToolId: stickyProps.id,
    },
  });
  setEditing(true);
  setTransform(true);
};

const handleBlur = (deleting) => {
  if (editToolData && Object.keys(editToolData).length > 0 && !deleting && !transform) {
    setEditing(false);
    dispatch(editStageTool(selectedFrame?.boardId,selectedFrame?._id, editToolData, editToolName));
   }
};

const handleChange = (text,fontSize) => {
  console.log("handle changes",text,fontSize)
  setTransform(false);
  dispatch({
    type: "UPDATE_EDIT_SELECTED_TOOL",
    payload: { name: "text", value:text },
  });
  dispatch({
    type: "UPDATE_EDIT_SELECTED_TOOL",
    payload: { name: "fontSize", value:fontSize },
  });
};

const handleTransformEnd = () =>{
  const node = shapeRef.current;
  const scaleX = node.scaleX();
  const scaleY = node.scaleY();
  node.scaleX(1);
  node.scaleY(1);
  let note = {
    x: node.x(),
    y: node.y(),
    width: Math.max(5, node.width() * scaleX),
    height: Math.max(5, node.height() * scaleY),
  };
  dispatch({
    type: "EDIT_SELECTED_TOOL",
    payload: {
      isEditToolSelected: true,
      editToolName: "stickyNotes",
      editToolData: {
        ...editToolData,
        ...note,
      },
      editedToolId: stickyProps.id,
    },
  });
  dispatch(
    editStageTool(
      selectedFrame?.boardId,
      selectedFrame?._id,
      {
        ...editToolData,
        ...note,
      },
      editToolName
    )
  );
}

  return (
    <>
      <Group key={key}>
        <Rect
            x={stickyProps.x}
            y={stickyProps.y}
            {...stickyProps}
            width={stickyProps.width}
            height={stickyProps.height}
            fill={stickyProps.color}
            {...(stickyStyle.border && {
              stroke: Colors.BLACK,
              strokeWidth: 2,
            })}
            cornerRadius={[5, 5, 30, 5]}
            draggable={stickyProps.draggable}
            onDragStart={handleDoubleClick}
            onDragEnd={(e) => onDragEnd(e.target.x(), e.target.y())}
            onTransformStart={handleDoubleClick}
            onTransformEnd={(e) => handleTransformEnd()}
            onClick={() => handleDoubleClick()}
            ref={shapeRef}
        />
        
        <EditableText
          {...stickyProps}
          ref={inputRef}
          x={stickyProps.x - (-8)}
          y={stickyProps.y - (-8)}
          width={stickyProps.width}
          height={stickyProps.height}
          isEditing={textWriting}
          onBlur={() => handleBlur(false)}
          onClick={()=>handleDoubleClick()}
          onChange={(text,fontSize)=>handleChange(text,fontSize)}
          fontSize={stickyProps.fontSize}
          italic={stickyProps.isItalic}
          textAlign={stickyProps?.textAlign}
          textColor={stickyProps?.textColor}
          fontWeight={stickyProps?.fontWeight}
          fontFamily={stickyProps.fontFamily}
          textDecoration={stickyProps.textDecoration}
          handleOpen={() => setEditing(true)}
          onSelect={handleDoubleClick}
          text={stickyProps.text}
          id={stickyProps.id}
          frameId={selectedFrame?._id}
        />
        {editing && (
          <StickyPopup
            x={stickyProps.x}
            y={
              stickyProps.y - 60
            }
            onClose={handleClose}
          />
        )}
      </Group>

      {editing && (
        <Transformer
          ref={transformStickyRef}
          rotateEnabled={false}
          flipEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 180 || newBox.height < 180) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default Sticky;
