const initialState = {
  frames: [],
  selectedFrame: {
    stickyNotes: [],
    rectangle: [],
    square: [],
    circle: [],
    triangle: [],
    pentagon: [],
    hexagon: [],
    textNotes: [],
    lines: [],
    undoStack: [],
    redoStack: [],
    usersAxis: [],
  },
  penstroke: {
    stroke: "black",
    strokeWidth: 2,
    width: 2,
  },
  selectedText: {},
  loggedInUser: {},
};

const FrameReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_FRAME":
      return {
        ...state,
        frames: action.payload,
      };
    // frame  update
    case "ADD_NEW_FRAME":
      return {
        ...state,
        frames: [...state.frames, action.payload],
      };
    // frame  update
    case "DELETE_FRAME":
      const updatedFrames = state.frames.filter(
        (frame) => frame._id !== action.payload
      );
      const lastFrameIndex = updatedFrames.length - 1;
      const selectedFrame = updatedFrames[lastFrameIndex] || null;

      return {
        ...state,
        frames: updatedFrames,
        selectedFrame: selectedFrame,
      };
    case "CLEAR_FRAME":
      return {
        ...state,
        frames: state.frames.map((frame) =>
          frame.id === action.payload?.frameId
            ? {
                ...frame,
                stickyNotes: [],
                rectangle: [],
                square: [],
                circle: [],
                triangle: [],
                pentagon: [],
                hexagon: [],
                textNotes: [],
                lines: [],
                undoStack: [],
                redoStack: [],
                lineShape: [],
              }
            : frame
        ),
        selectedFrame: {
          ...state.selectedFrame,
          stickyNotes: [],
          rectangle: [],
          square: [],
          circle: [],
          triangle: [],
          pentagon: [],
          hexagon: [],
          textNotes: [],
          lines: [],
          undoStack: [],
          redoStack: [],
          lineShape:[],
        },
      };
    case "UPDATE_FRAME":
      return {
        ...state,
        selectedFrame: {
          ...state.selectedFrame,
          [action.payload.type]: [
            ...state.selectedFrame[action.payload.type],
            action.payload.data,
          ],
          undoStack: [
            ...state.selectedFrame.undoStack,
            { type: action.payload.type, data: action.payload.data },
          ],
        },
      };

    case "SET_SELECTED_LINES":
      const { data } = action.payload;
      console.log("data", data, action.payload.type);
      return {
        ...state,
        selectedFrame: {
          ...state.selectedFrame,
          [action.payload.type]:
            action.payload.length > 0
              ? [
                  ...state.selectedFrame[action.payload.type].map(
                    (obj, index) =>
                      index ===
                      state.selectedFrame[action.payload.type].length - 1
                        ? [...obj, ...data]
                        : obj
                  ),
                ]
              : [...state.selectedFrame[action.payload.type], data], // Add the new data directly
        },
      };
    case "UPDATE_SELECTED_FRAME":
      return {
        ...state,
        selectedFrame: state.selectedFrame?._id===action.payload.frameId?{
          ...state.selectedFrame,
          gridSize: action.payload.data.gridSize,
          stageHeight: action.payload.data.stageHeight,
          stageWidth: action.payload.data.stageWidth,
          scale: action.payload.data.scale,
          zoomPercentage: action.payload.data.zoomPercentage,
        }:state.selectedFrame,
      };

    case "UPDATE_STROKE":
      return {
        ...state,
        penstroke: {
          ...state.penstroke,
          [action.payload.key]: action.payload.value,
        },
      };
    case "UPDATE_USERS_FRAME":
      return {
        ...state,
        selectedFrame: {
          ...state.selectedFrame,
          usersAxis: action.payload.usersAxis,
        }

      }  
    case "UPDATE_PENSTROKE":
      return {
        ...state,
        penstroke: {
          width: action.payload.width,
          stroke: action.payload.stroke,
          strokeWidth: action.payload.strokeWidth,
        },
      };  
    case "UPDATE_NOTE_FRAME":
      return {
        ...state,
        selectedFrame: {
          ...state.selectedFrame,
          stickyNotes: state.selectedFrame.stickyNotes.map((item) =>
            item.id === action.payload.id ? action.payload.note : item
          ),
        },
      };
    case "SET_SELECTED_TEXT":
      return {
        ...state,
        selectedText: action.payload,
      };
   
    case "SET_SELECTED_FRAME":
      return {
        ...state,
        selectedFrame: action.payload,
      };

    case "UPDATE_SELECTED_EDIT_TOOL":
      return {
        ...state,
        selectedFrame: {
          ...state.selectedFrame,
          [action.payload.type]: state.selectedFrame[action.payload.type].map(
            (item) =>
              item.id === action.payload.id ? action.payload.data : item
          ),
          undoStack: state.selectedFrame.undoStack.map((item) =>
            item.data.id === action.payload.id
              ? { ...item, data: action.payload.data }
              : item
          ),
        },
      };

    case "REMOVE_FROM_FRAME":
      const { type, undoData } = action.payload;
      const updatedSelectedFrame = { ...state.selectedFrame };
      if (Array.isArray(updatedSelectedFrame[type])) {
        updatedSelectedFrame[type] = updatedSelectedFrame[type].filter(
          (item) => item.id !== undoData.id
        );
        updatedSelectedFrame.undoStack = updatedSelectedFrame.undoStack.filter(
          (item) => item.data.id !== undoData.id
        );
        updatedSelectedFrame.redoStack = [
          ...updatedSelectedFrame.redoStack,
          { data: undoData, type },
        ];
      }

      return {
        ...state,
        selectedFrame: updatedSelectedFrame,
      };

    case "REDO_FRAME":
      return {
        ...state,
        selectedFrame: {
          ...state.selectedFrame,
          [action.payload.type]: [
            ...state.selectedFrame[action.payload.type],
            action.payload.data,
          ],
          undoStack: [
            ...state.selectedFrame.undoStack,
            { data: action.payload.data, type: action.payload.type },
          ],
          redoStack: state.selectedFrame.redoStack.filter(
            (item) => item.data.id !== action.payload.data.id
          ),
        },
      };
    case "SET_USERS_DATA":
      return {
        ...state,
        loggedInUser: action.payload,
      };
    default:
      return state;
  }
};

export default FrameReducer;
